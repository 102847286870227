.confirmationModal {
  position: fixed;
  top: 25px;
  right: 35px !important;
  border-radius: 4px;
  width: 385px;
  height: auto;
  z-index: 9999;
}

.saveIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(47, 128, 237, 0.15);
}

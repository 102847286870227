@font-face {
  font-family: "Poppins";
  src: url(./assets/fonts/poppins/Poppins-Regular.ttf);
}

:root {
  --primary-grey: #181a25;
  --secondary-grey: #11141c;
  --primary-blue: #1b2b3b;
  --secondary-blue: #2d9cdb;
}

/* Scroll Bar Custom Stylization */

.overflow-style::-webkit-scrollbar-track,
.overflow-style::-webkit-scrollbar-track:horizontal {
  padding: 2px 0;
  background-color: var(--primary-blue);
}

.overflow-style::-webkit-scrollbar {
  width: 7.5px;
}

.overflow-style::-webkit-scrollbar:horizontal {
  height: 7.5px;
}

.overflow-style::-webkit-scrollbar-thumb,
.overflow-style::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--secondary-blue);
  /* border: 1px solid #000; */
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.intuita-btn {
  background-color: var(--secondary-grey);
  color: white;
  padding: 8px 16px;
  border: 1px solid #222535;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

/* .intuita-btn:hover {
  box-shadow: 1px 1px 0 var(--intuita-secondary);
  color: white !important; 
  border: none !important;
  background-color: var(--intuita-main);
} */

/* .intuita-btn:only-of-type {
  margin-right: 0;
}

.intuita-btn:first-child:not(last-child) {
  margin-right: 0.75rem;
} */

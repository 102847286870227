.component-container {
  background-color: var(--primary-grey);
  border: 1px solid #222535;
  border-radius: 4px;
  padding: 1em;
}

.activeBtn {
  background-color: #223448 !important;
  border-color: var(--secondary-blue) !important;
}

.react-icon {
  cursor: pointer;
  min-width: 1.25em;
  min-height: 1.25em;
}

.tbl td {
  font-weight: 700;
}

.inpt-option {
  width: 50% !important;
  height: 25px !important;
  background-color: var(--secondary-grey) !important;
  border: 3px solid var(--secondary-blue) !important;
  border-radius: 0 !important;
  color: white !important;
  text-align: center !important;
  opacity: 0.4;
}

.inpt-option:focus {
  background-color: var(--primary-grey);
  color: white;
}

p {
  font-size: 14px;
}

h4 {
  font-size: 20px !important;
}

.btn_submits-style {
  border: 1px solid #2d9cdb;
  height: 32px;
  width: 86px;
}

.card-style {
  background: var(--primary-grey);
  /* border: 1px solid #2d9cdb; */
  border-radius: 4px;
  padding-bottom: 0.25rem;
}

.card-style h4:first-of-type {
  margin-top: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.tippy-box[data-theme~="light"] {
  background-color: var(--primary-grey);
  color: white;
  border: 1px solid #2d9cdb;
}

.sidenav-icon:active {
  border: 1px solid white;
}

.navbar-vertical {
  position: fixed !important;
  display: flex !important;
  flex-direction: column;
  align-items: flex-start !important;
  padding: 1em !important;
  height: 100vh;
  width: 15.5em;
}

.navbar-link-title {
  padding: 1em;
  font-size: 1em;
}

.navbar-site-title {
  font-size: 1.3em;
  font-weight: bolder;
  font-style: italic;
  word-wrap: break-word;
  white-space: normal;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
  color: white;
}
